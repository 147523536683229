import { type PropsWithChildren } from "react";
import {
  AntdConfigProvider,
  Layout,
} from "syngenta-digital-cropwise-react-ui-kit";

export const LayoutWithoutMenu = ({
  children,
}: PropsWithChildren): JSX.Element => {
  return (
    <AntdConfigProvider>
      <Layout>{children}</Layout>
    </AntdConfigProvider>
  );
};

export default LayoutWithoutMenu;
