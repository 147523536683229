export const isAccessTokenAvailable = (): boolean =>
  !!localStorage.getItem("access_tokens");

export const setAccessToken = (tokens: any): void => {
  localStorage.setItem("access_tokens", JSON.stringify(tokens));
};

export const getAccessToken = (): string => {
  const accessToken: null | string = localStorage.getItem("access_tokens");
  return accessToken ?? "";
};

export const getMapBoxAccessToken = (): string => {
  return process.env.REACT_APP_MAPBOX_TOKEN ?? "";
};

export const getMetaBlueAPIToken = (): string => {
  return process.env.REACT_APP_METEOBLUE_WEATHER_API_TOKEN ?? "";
};

export const clearAccessToken = (): void => {
  localStorage.removeItem("access_tokens");
};

export const everyNth = (arr: any[], nth: number): any =>
  arr.filter((e, i) => i % nth === nth - 1);

export const getHeaders = (): { common: { Authorization: string } } => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
  },
});

export const getCurrentEnv = (): any => {
  const currentEnv = process.env.REACT_APP_ENV;
  switch (currentEnv) {
    case "local":
      return "local";
    case "dev":
      return "dev";
    case "staging":
      return "staging";
    default:
      return currentEnv;
  }
};

export function osfunction(): string {
  const os = window.navigator.userAgent;
  let finalOs = "";
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  } else if (os.search("Mac") !== -1) {
    finalOs = "MacOS";
  }
  return finalOs;
}
