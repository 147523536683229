export const APP_GOOGLE_URL =
  "https://play.google.com/store/apps/details?id=pl.syngenta.infopole";
export const APP_APPLE_URI =
  "https://apps.apple.com/pl/app/myfield/id1502095497?l=pl?l=pl";
export const TECHINCAL_AGRONOMIC_REPORTS =
  "https://www.syngenta.pl/infopole-komunikat";
export const weatherCEHumUrl = (
  countrycode: string = "",
  languagecode: string = "",
  lat: number = 0,
  long: number = 0
): string => {
  return `https://visualization.cehub.syngenta-ais.com/forecastweatherdata?language=${languagecode}&country=${countrycode}&numberofforecast=5&Lat=${lat}&Long=${long}&UserSpraywdws=sw_aerialspraygeneric&CountrySpraywdws=sw_groundsprayherbicide_ld%7Csw_groundsprayherbicide_sd&securitykey=BsQIdrsA5HiGN3u2Z1pKXB/zhk3kfQjRbpsx1VBumnfYhd6BKz0Y25E6jpZA/cBWkd/vq1A7CpHL7179vbXzDLdoXd871Z+OiG7gtePTiuRohRBgwz1yl3j6DCnEKA2An/JGBr0ZFpmjVf87i1NKfMVtb+mHsO6+9Iy1Tv0cELLkptsh+yBw0udNaYd1ZI736mox/WLyLZo=`;
};
