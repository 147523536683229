import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { ApplicationState } from "../../core/redux/reducers";

export const EditField = (): JSX.Element => {
  const profileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (profileData?.defaultOrgId && profileData?.defaultPropertyId) {
      const deepLink = `${
        process.env.REACT_APP_CROPWISE_APP_URL ?? ""
      }/deep-actions/create-field?org_id=${
        profileData?.defaultOrgId
      }&property_id=${profileData?.defaultPropertyId}&callback_uri=${
        process.env.REACT_APP_URL
      }/mapview`;
      window.location.href = deepLink;
    } else {
      navigate("/mapview", { replace: true });
    }
  }, [profileData]);

  return <>Redirecting to edit field....</>;
};

export default EditField;
