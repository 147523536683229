import requests from "./axios";

const ProductTemplateApi = {
  getTemplateDetails: async (uniqueId: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/template-config/content/${uniqueId}`
    ),
  getBannerTemplateApi: async (countryCode: string): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/banners/?countryCode=${countryCode}`
    ),
};

export default ProductTemplateApi;
