import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import { setTemplateDetails } from "../../core/redux/reducers/productTemplateSlice";
import type { ApplicationState } from "../../core/redux/reducers";
import ConstantKey from "../../core/locale/strings.json";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";
import "./productRecommendation.less";

const ProductRecommendation = (): JSX.Element => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t: translate } = useTranslation();
  const ProductTemplate = useSelector(
    (state: ApplicationState) => state.productTemplateDetails
  );

  useEffect(() => {
    if (params.id) {
      ProductTemplateApi.getTemplateDetails(params?.id)
        .then((response) => {
          dispatch(setTemplateDetails(response));
        })
        .catch(() => {});
    }
  }, [params?.id]);
  const onClickButton = (url: string | undefined): any => {
    window.open(url, "_blank");
  };
  return (
    <div className="summaryinsightsroot">
      {ProductTemplate?.templateDetails && (
        <>
          {ProductTemplate?.templateDetails?.bannerLogo &&
            ProductTemplate?.templateDetails?.bannerLogo !== "N/A" && (
              <div className="top-boxsty summarytechincalreport">
                <img
                  className="producticon"
                  alt="bannerIcon"
                  src={ProductTemplate?.templateDetails?.bannerLogo}
                />
              </div>
            )}
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="pagesty headingpagecontent">
                  {ProductTemplate?.templateDetails?.tag &&
                    ProductTemplate?.templateDetails?.tag !== "N/A" && (
                      <p>{ProductTemplate?.templateDetails?.tag}</p>
                    )}
                  {ProductTemplate?.templateDetails?.productName &&
                    ProductTemplate?.templateDetails?.productName !== "N/A" && (
                      <h2>{ProductTemplate?.templateDetails?.productName}</h2>
                    )}
                  {ProductTemplate?.templateDetails?.productVariation &&
                    ProductTemplate?.templateDetails?.productVariation !==
                      "N/A" && (
                      <p>
                        {ProductTemplate?.templateDetails?.productVariation}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="line"></div>
              <div className="col-md-6">
                {ProductTemplate?.templateDetails?.tagLine &&
                  ProductTemplate?.templateDetails?.tagLine !== "N/A" && (
                    <p>{ProductTemplate?.templateDetails?.tagLine}</p>
                  )}
              </div>
              {ProductTemplate?.templateDetails?.primaryCTAText &&
                ProductTemplate?.templateDetails?.primaryCTAText !== "N/A" && (
                  <div className="col-md-6">
                    <button
                      className="recommendation-field-btn"
                      onClick={() => {
                        onClickButton(
                          ProductTemplate?.templateDetails?.primaryCTALink
                        );
                      }}
                    >
                      {ProductTemplate?.templateDetails?.primaryCTAText}
                    </button>
                  </div>
                )}
              <div className="line"></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {ProductTemplate?.templateDetails?.proposition &&
                  ProductTemplate?.templateDetails?.proposition !== "N/A" && (
                    <div className="headingpagecontent">
                      <h2>{translate(ConstantKey.PRODUCT_OVERVIEW)}</h2>
                      <p> {ProductTemplate?.templateDetails?.proposition}</p>
                    </div>
                  )}
                <div className="line"></div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {ProductTemplate?.templateDetails.demoTitle &&
                ProductTemplate?.templateDetails.demoTitle !== "N/A" && (
                  <div className="col-md-12">
                    <div className="headingpagecontent">
                      <h2> {ProductTemplate?.templateDetails.demoTitle}</h2>
                    </div>
                    {ProductTemplate?.templateDetails?.demoLink &&
                      ProductTemplate?.templateDetails?.demoLink !== "N/A" && (
                        <div className="videos">
                          <iframe
                            className="video"
                            width="98%"
                            height="315"
                            src={ProductTemplate?.templateDetails?.demoLink}
                            title="YouTube video player"
                          >
                            {" "}
                          </iframe>
                        </div>
                      )}
                    <div className="headingpagecontent">
                      {" "}
                      {ProductTemplate.templateDetails?.demotext &&
                        ProductTemplate.templateDetails?.demotext !== "N/A" && (
                          <p>{ProductTemplate.templateDetails?.demotext}</p>
                        )}
                    </div>
                    <div className="line"></div>
                  </div>
                )}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headingpagecontent">
                  {ProductTemplate?.templateDetails?.testimonialTitle &&
                    ProductTemplate?.templateDetails?.testimonialTitle !==
                      "N/A" && (
                      <h2>
                        {" "}
                        {ProductTemplate?.templateDetails?.testimonialTitle}
                      </h2>
                    )}
                  {ProductTemplate?.templateDetails?.testimonialText &&
                    ProductTemplate?.templateDetails?.testimonialText !==
                      "N/A" && (
                      <p>{ProductTemplate?.templateDetails?.testimonialText}</p>
                    )}
                </div>
              </div>
              {ProductTemplate?.templateDetails?.testimonialLink &&
                ProductTemplate?.templateDetails?.testimonialLink !== "N/A" && (
                  <div className="col-md-6">
                    <div className="videos">
                      <iframe
                        className="video"
                        width="98%"
                        height="315"
                        src={ProductTemplate?.templateDetails?.testimonialLink}
                        title="YouTube video player"
                      >
                        {" "}
                      </iframe>
                    </div>
                  </div>
                )}
              <div className="line"></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headingpagecontent">
                  {ProductTemplate?.templateDetails?.resultTitle &&
                    ProductTemplate?.templateDetails?.resultTitle !== "N/A" && (
                      <h2> {ProductTemplate?.templateDetails?.resultTitle}</h2>
                    )}
                  {ProductTemplate?.templateDetails?.resultText &&
                    ProductTemplate?.templateDetails?.resultText !== "N/A" && (
                      <p>{ProductTemplate?.templateDetails?.resultText}</p>
                    )}
                </div>
              </div>
              {ProductTemplate?.templateDetails?.resultLink &&
                ProductTemplate?.templateDetails?.resultLink !== "N/A" && (
                  <div className="col-md-6">
                    <div className="videos">
                      <iframe
                        className="video"
                        width="98%"
                        height="315"
                        src={ProductTemplate?.templateDetails?.resultLink}
                        title="YouTube video player"
                      >
                        {" "}
                      </iframe>
                    </div>
                  </div>
                )}
              <div className="line"></div>
            </div>
          </div>
          <div className="websection">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="headingpagecontent">
                    {ProductTemplate?.templateDetails?.productInfo &&
                      ProductTemplate?.templateDetails?.productInfo !==
                        "N/A" && (
                        <>
                          <h2>
                            {" "}
                            {translate(ConstantKey.KEY_BENEFITS_AND_FEATURES)}
                          </h2>
                          <h6>{translate(ConstantKey.USAGE)}</h6>
                          <p>{ProductTemplate?.templateDetails?.productInfo}</p>
                        </>
                      )}
                    {ProductTemplate?.templateDetails?.secondaryCTAText &&
                      ProductTemplate?.templateDetails?.secondaryCTAText !==
                        "N/A" && (
                        <button
                          className="Gettechnical"
                          onClick={() => {
                            onClickButton(
                              ProductTemplate?.templateDetails?.secondaryCTALink
                            );
                          }}
                        >
                          {ProductTemplate?.templateDetails?.secondaryCTAText}
                        </button>
                      )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="commongib">
                    <div className="headingpagecontent">
                      <h2> Good for:</h2>
                    </div>
                    <ul className="liststy">
                      <li>
                        {" "}
                        <b>common gibberish</b>
                      </li>
                      <li>
                        {" "}
                        <b>small geranium </b>{" "}
                      </li>
                      <li>
                        {" "}
                        <b>chaber bławatek</b>
                      </li>
                      <li>
                        {" "}
                        <b>one-sided weed</b>
                      </li>
                      <li> common bubo</li>
                      <li> field violet</li>
                      <li> common chickweed</li>
                      <li> purple light</li>
                      <li> white quinoa</li>
                      <li>wry-necked grasshopper </li>
                      <li> field moorhen</li>
                      <li> mak full</li>
                      <li> maruna bezwonna</li>
                      <li> Ivy Speedwell</li>
                      <li> clinging bedstraw</li>
                      <li> black nightshade</li>
                    </ul>
                  </div>
                </div> */}
                <div className="line"></div>
              </div>
            </div>
          </div>

          {/* <div className="mobsection">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="headingpagecontent">
                    <h2> Key Benefits and Features</h2>
                    <div className="line"></div>
                    <ul className="liststy">
                      <li>
                        {" "}
                        <b>common gibberish</b>
                      </li>
                      <li>
                        {" "}
                        <b>small geranium </b>{" "}
                      </li>
                      <li>
                        {" "}
                        <b>chaber bławatek</b>
                      </li>
                      <li>
                        {" "}
                        <b>one-sided weed</b>
                      </li>
                      <li> common bubo</li>
                      <li> field violet</li>
                      <li> common chickweed</li>
                      <li> purple light</li>
                      <li> white quinoa</li>
                      <li>wry-necked grasshopper </li>
                      <li> field moorhen</li>
                      <li> mak full</li>
                      <li> maruna bezwonna</li>
                      <li> Ivy Speedwell</li>
                      <li> clinging bedstraw</li>
                      <li> black nightshade</li>
                    </ul>
                  </div>
                  <button className="Gettechnical">
                    Get Technical Information{" "}
                  </button>
                </div>
                <div className="line"></div>
              </div>
            </div>
          </div> */}

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="headingpagecontent conatctdatails">
                  <h2>{translate(ConstantKey.CONTACT)}</h2>
                  {ProductTemplate?.templateDetails?.phone &&
                    ProductTemplate?.templateDetails?.phone !== "N/A" && (
                      <p>
                        {" "}
                        <img
                          src={phoneIcon}
                          alt="phoneIcon"
                          className="phoneIcon"
                        />
                        <span>{ProductTemplate?.templateDetails?.phone}</span>
                      </p>
                    )}
                  {ProductTemplate?.templateDetails?.email &&
                    ProductTemplate?.templateDetails?.email !== "N/A" && (
                      <p>
                        <img
                          src={mailIcon}
                          alt="mailIcon"
                          className="mailIcon"
                        />
                        <span>{ProductTemplate?.templateDetails?.email}</span>
                      </p>
                    )}
                </div>

                {ProductTemplate?.templateDetails?.thirdCTALink &&
                  ProductTemplate?.templateDetails?.thirdCTALink !== "N/A" && (
                    <button
                      className="wereto"
                      onClick={() => {
                        onClickButton(
                          ProductTemplate?.templateDetails?.thirdCTALink
                        );
                      }}
                    >
                      {ProductTemplate?.templateDetails?.thirdCTAText}
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className="spassty"></div>
          {/* 
          <div className="mobisecsection">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="mobibg headingpagecontent">
                    <h2> About the product </h2>
                    <h6>Usage</h6>
                    <p>
                      {" "}
                      A herbicide in the form of a suspension-emulsion to be
                      diluted with water, applied to the soil or on leaves,
                      intended for pre-emergence and post-emergence control of
                      monocotyledonous (mainly millet) and dicotyledonous weeds
                      in corn.
                    </p>
                    <h6> Packing Size</h6>
                    <p> 1 l, 5 l, 20 l, 100 l</p>
                    <h6>Composition/active substances</h6>
                    <p>
                      37,5 g/l ou 3,39 % (p/p) mesotriona + 312,5 g/l ou 28,2 %
                      (p/p) S-metolacloro + 187,5 g/l ou 16,9% (p/p)
                      terbutilazina
                    </p>
                    <h6>Formulation:</h6>
                    <p>Suspo-emulsão (SE)</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
export default ProductRecommendation;
