import { Suspense, useCallback, useEffect } from "react";
import { Layout, notification } from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CookiesProvider } from "react-cookie";

import MainRoutes from "./routes";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import { Loader } from "./shared/components/Loader";
import { ThemeProvider } from "./shared/layout/ThemeProvider";
import ScrollToTop from "./shared/components/scrollToTop/scrollToTop";
import {
  setDefaultLocation,
  setDefaultOrgId,
  setDefaultPropertyCoordinates,
  setDefaultPropertyId,
  setIsGrowerExist,
  setLanguage,
  setProfileData,
  setProfileDataLoading,
  setRefreshUserData,
  setUserLocation,
  setValidUser,
} from "./core/redux/reducers/accountProfileSlice";
import UserApi from "./core/api/userApi";
import { isAccessTokenAvailable } from "./core/utils/common-function";
import type { ProfileInitialState } from "./core/types/accountProfile.dto";
import { setCurrentLanguage } from "./core/redux/reducers/homePageSlice";
import SplashScreen from "./pages/splash-screen";

import "./index.less";

function App(): JSX.Element | null {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfileDetails: ProfileInitialState = useSelector(
    (state: any) => state.accountDetials
  );

  useEffect(() => {
    if (isAccessTokenAvailable()) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (isAccessTokenAvailable() && userProfileDetails.refreshUserData) {
      getUserDetails(true);
    }
  }, [userProfileDetails.refreshUserData]);

  const failedTofetchingUser = useCallback((message: string) => {
    notification.error({
      message,
      description: "",
      placement: "topRight",
    });
    dispatch(setProfileDataLoading(false));
    navigate("/something-went-wrong", { replace: true });
  }, []);

  const onFetchUser = useCallback((response: any) => {
    dispatch(setProfileData(response));
    dispatch(setLanguage(response?.locale));
    dispatch(setDefaultOrgId(response?.defaultOrgId));
    if (response?.growerRefId) {
      dispatch(setIsGrowerExist(true));
    } else {
      dispatch(setIsGrowerExist(false));
    }
    if (response?.defaultLocation) {
      dispatch(setDefaultLocation(response?.defaultLocation));
      if (
        response?.defaultLocation?.city &&
        response?.defaultLocation?.countryCode
      ) {
        dispatch(
          setUserLocation(
            `${
              response?.defaultLocation?.city
            }, ${response?.defaultLocation?.countryCode.toLocaleUpperCase()}`
          )
        );
        localStorage.setItem("userLanguage", response.locale);
        dispatch(setCurrentLanguage(response.locale));
      } else if (
        response.defaultLocation?.place &&
        response?.defaultLocation?.countryCode
      ) {
        dispatch(
          setUserLocation(
            `${
              response.defaultLocation?.place
            }, ${response?.defaultLocation?.countryCode.toLocaleUpperCase()}`
          )
        );
        localStorage.setItem("userLanguage", response.locale);
        dispatch(setCurrentLanguage(response.locale));
      }
      if (response?.defaultPropertyId) {
        dispatch(setDefaultPropertyId(response?.defaultPropertyId));
      }
      if (
        response?.defaultLocation?.latitude &&
        response?.defaultLocation?.longitude
      ) {
        dispatch(
          setDefaultPropertyCoordinates({
            lat: response?.defaultLocation?.latitude,
            lng: response?.defaultLocation?.longitude,
          })
        );
      }
    } else {
      navigate("/drop-a-pin", { replace: true });
    }
    dispatch(setValidUser(true));
    dispatch(setProfileDataLoading(false));
  }, []);

  const getUserDetails = useCallback((isRefresh: boolean = false) => {
    if (isRefresh) {
      dispatch(setRefreshUserData(false));
    }
    dispatch(setProfileDataLoading(true));
    UserApi.fetchCurrentUserInfo()
      .then((response) => {
        if (response) {
          onFetchUser(response);
        } else {
          failedTofetchingUser("Something went wrong!");
        }
      })
      .catch((error: any) => {
        failedTofetchingUser(
          error?.cause?.data?.detail
            ? error?.cause?.data?.detail
            : "Something went wrong!"
        );
      });
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <div className="app-root">
            {/* ScrollToTop component inside App component */}
            <ScrollToTop />
            <Layout className="cw-page-container">
              {userProfileDetails.loading ? (
                <SplashScreen showInitialLoader={true} />
              ) : (
                <Suspense
                  fallback={<Loader mask={true} message="Loading..." />}
                >
                  <MainRoutes />
                </Suspense>
              )}
            </Layout>
          </div>
        </CookiesProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
