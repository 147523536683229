import { useEffect, useState } from "react";

import { Divider, Space } from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";

import myfieldLogo from "../../../assets/images/myfieldLogo.svg";
import menuIcon from "../../../assets/icons/menu.svg";
import { type GlobalHeaderDTO } from "../../../core/types/headers.dto";

export const MyFieldLogo = ({
  collapsedMenu,
  setCollapsedMenu,
  hideSideBarBtn,
}: GlobalHeaderDTO): JSX.Element => {
  const breakpoint = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width < breakpoint) {
      setCollapsedMenu(true);
    } else {
      setCollapsedMenu(false);
    }
  }, [width]);

  return (
    <Space
      align="start"
      className="mf-logo-sec"
      direction="horizontal"
      size="small"
    >
      {!hideSideBarBtn && (
        <button
          className="mf-btn-img mf-height-100"
          onClick={() => {
            setCollapsedMenu(!collapsedMenu);
          }}
        >
          <img
            src={menuIcon}
            className="mf-cursor-pointer mf-height-100 mf-mb-10"
            alt="menuIcon"
          />
        </button>
      )}
      <button
        className="mf-btn-img mf-height-100 mf-cursor-pointer"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        <img src={myfieldLogo} className="appLogo mf-mb-10" alt="logoIcon" />
      </button>
      <Divider type="vertical" className="mf-mb-5" />
    </Space>
  );
};
