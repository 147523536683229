import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConstantKey from "../../core/locale/strings.json";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import synDigitalLogo from "../../assets/images/synDigitalLogo.svg";
import "./splashScreen.less";

interface SplashScreenDto {
  showInitialLoader?: boolean;
}
export const SplashScreen = (props: SplashScreenDto): JSX.Element => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const navigateLogin = (): void => {
    navigate("/login");
  };
  const navigateSignUp = (): void => {
    navigate("/signup");
  };

  return (
    <div className="landing-screen-container">
      <div className="landing-screen-content">
        <img
          className="syn-field-logo"
          src={synMyFieldLogo}
          alt="Syngenta Myfield Logo"
        />
        {props.showInitialLoader ? (
          <div className="syn-loader"></div>
        ) : (
          <div className="login-logout-container">
            <div className="myfield-welcome-message">
              {translate(ConstantKey.WELCOME_MY_FIELD)}
            </div>
            <button className="splash-login-btn" onClick={navigateLogin}>
              {translate(ConstantKey.SIGN_IN)}
            </button>
            <div className="splash-signup">
              {translate(ConstantKey.DONOT_HAVE_ACCOUNT)}{" "}
              <button className="button-link" onClick={navigateSignUp}>
                {translate(ConstantKey.CREATE_YOUR_ACCOUNT)}
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="syn-field-support">
        {translate(ConstantKey.NEED_HELP)}{" "}
        <a className="splash-signup-link" href="mailto:support@cropwise.com">
          {translate(ConstantKey.SUPPORT_EMAIL)}
        </a>
        <br />
        <img
          className="syn-support-logo"
          src={synDigitalLogo}
          alt="Syngenta Digital Logo"
        />
      </div>
    </div>
  );
};

export default SplashScreen;
