import { lazy } from "react";
import AuthenticationRedirectComponent from "../pages/authentication-redirect-component";
import type { AllRoutesDto } from "../core/types/routes.dto";
import AppRoutes from "./appRoutes";
import AppDashboard from "../pages/dashboard";
import LoginComponent from "../pages/login";
import SignUpComponent from "../pages/signup";
import DeepActionPage from "../pages/deep-action";
import CreateOrganization from "../pages/deep-action/createOrganization";
import EditOrganization from "../pages/deep-action/editOrganization";
import CreateProperty from "../pages/deep-action/createProperty";
import EditProperty from "../pages/deep-action/editProperty";
import CreateField from "../pages/deep-action/createField";
import EditField from "../pages/deep-action/editField";
import DrawField from "../pages/deep-action/drawField";
import SplashScreen from "../pages/splash-screen";
import SummaryInsights from "../pages/summaryinsights/summaryInsights";
import ProductRecommendation from "../pages/product-template/productRecommendation";
import ErrorHandler from "../pages/error-handler";

const DropAPin = lazy(async () => await import("../pages/drop-a-pin"));
const MapView = lazy(async () => await import("../pages/mapView"));

export const routes: AllRoutesDto = {
  openRoutes: [
    {
      path: "/something-went-wrong",
      element: <ErrorHandler />,
      layoutWithoutSideMenu: true,
      layoutWithoutHeader: true,
    },
  ],
  authRoutes: [
    {
      path: "/splash",
      element: <SplashScreen />,
    },
    {
      path: "/login",
      element: <LoginComponent />,
    },
    {
      path: "/authenticate",
      element: <AuthenticationRedirectComponent />,
    },
    {
      path: "/signup",
      element: <SignUpComponent />,
    },
  ],
  protectedRoutes: [
    {
      path: "/dashboard",
      element: <AppDashboard />,
    },
    {
      path: "/mapview",
      element: <MapView />,
    },
    {
      path: "/deep-action",
      element: <DeepActionPage />,
    },
    {
      path: "/create-organization",
      element: <CreateOrganization />,
    },
    {
      path: "/edit-organization",
      element: <EditOrganization />,
    },
    {
      path: "/create-property",
      element: <CreateProperty />,
    },
    {
      path: "/edit-property",
      element: <EditProperty />,
    },
    {
      path: "/create-field",
      element: <CreateField />,
    },
    {
      path: "/edit-field",
      element: <EditField />,
    },
    {
      path: "/draw-field",
      element: <DrawField />,
    },
    {
      path: "/drop-a-pin",
      element: <DropAPin />,
      layoutWithoutSideMenu: true,
    },
    {
      path: "/summaryinsights",
      element: <SummaryInsights />,
    },
    {
      path: "/product-recommendations/:id",
      element: <ProductRecommendation />,
    },
  ],
};

export const MainRoutes = (): JSX.Element => {
  return <AppRoutes routes={routes}></AppRoutes>;
};

export default MainRoutes;
