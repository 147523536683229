import React from "react";
import { AuthenticationOptions } from "../../core/types/authentication.dto";

export const SignUpComponent = (): JSX.Element => {
  const initOAuth2Flow = (returnStateValue?: string): void => {
    const returnState = returnStateValue
      ? `&state=${btoa(returnStateValue)}`
      : "";
    const oauthURL = process.env.REACT_APP_CROPWISE_BASE_URL;
    if (oauthURL) {
      window.location.href = `${oauthURL}/signup?response_type=code&client_id=${AuthenticationOptions.clientId}&redirect_uri=${AuthenticationOptions.redirectUri}${returnState}`;
    }
  };

  React.useEffect(() => {
    initOAuth2Flow();
  }, []);
  return <p>Redirecting to Signup Page...</p>;
};

export default SignUpComponent;
