import forecastSlice, { type ForecastInitialState } from "./forecastSlice";
import homePageSlice, { type HomePageInitialState } from "./homePageSlice";
import accountProfileSlice from "./accountProfileSlice";
import { type ProfileInitialState } from "../../types/accountProfile.dto";
import mapviewSlice, { type MapviewState } from "./mapviewSlice";
import fieldDetailsSlice from "./fieldDetailsSlice";
import { type FieldDetailsInitialStateDto } from "../../types/fieldDetails.dto";
import productTemplateSlice, {
  type ProductTemplateState,
} from "./productTemplateSlice";
export interface ApplicationState {
  accountDetials: ProfileInitialState | undefined;
  forecast: ForecastInitialState | undefined;
  homepage: HomePageInitialState | undefined;
  mapviewdetails: MapviewState | undefined;
  farmDetails: FieldDetailsInitialStateDto | undefined;
  productTemplateDetails: ProductTemplateState | undefined;
}

export const reducers = {
  accountDetials: accountProfileSlice,
  forecast: forecastSlice,
  homepage: homePageSlice,
  mapviewdetails: mapviewSlice,
  farmDetails: fieldDetailsSlice,
  productTemplateDetails: productTemplateSlice,
};
