import { useCallback, useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import LayoutWithMenu from "./LayoutWithMenu";
import LayoutWithoutMenu from "./LayoutWithoutMenu";
import SplashScreen from "../../../pages/splash-screen";

import { routes } from "../../../routes";
import { type ProfileInitialState } from "../../../core/types/accountProfile.dto";
import { isAccessTokenAvailable } from "../../../core/utils/common-function";
import { type RoutesDto } from "../../../core/types/routes.dto";

export const AppLayout = (): JSX.Element => {
  const userProfileDetails: ProfileInitialState = useSelector(
    (state: any) => state.accountDetials
  );

  const [routesWithoutMenu, setRoutesWithoutMenu] = useState<RoutesDto[]>([]);
  const [isPending, startTransition] = useTransition();

  const getRoutesWithoutMenu = useCallback(() => {
    startTransition(() => {
      const openAndAuthRoutes = [
        ...routes.protectedRoutes,
        ...routes.openRoutes,
      ];
      const routesHideMenu = openAndAuthRoutes.filter((item) => {
        return (
          item?.layoutWithoutSideMenu === true ||
          item?.layoutWithoutHeader === true
        );
      });
      setRoutesWithoutMenu([...routesHideMenu]);
    });
  }, []);

  useEffect(() => {
    getRoutesWithoutMenu();
  }, []);

  if (userProfileDetails.loading || isPending)
    return <SplashScreen showInitialLoader={true} />;

  return (
    <>
      {isAccessTokenAvailable() ? (
        <LayoutWithMenu routesWithoutMenu={routesWithoutMenu}>
          <Outlet />
        </LayoutWithMenu>
      ) : (
        <LayoutWithoutMenu>
          <Outlet />
        </LayoutWithoutMenu>
      )}
    </>
  );
};

export default AppLayout;
