import axios, { type AxiosError } from "axios";

export const getAuthorizationHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
  authorization:
    "Basic " + btoa(`${process.env.REACT_APP_OAUTH_CLIENT_ID ?? ""}:${""}`),
};

export const baseURL = process.env.REACT_APP_BASE_API_URL;

export const axiosRequest = axios.create({
  baseURL,
});

const requests = {
  get: async (url: string) => await axios.get(url),
  post: async (url: string, body: any = null) => await axios.post(url, body),
  put: async (url: string, body: any) => await axios.put(url, body),
  del: async (url: string, body: any) => await axios.delete(url, body),
};

// request interceptors
axios.interceptors.request.use(
  (config) => {
    const jwt = localStorage.getItem("access_tokens");
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  },
  async (error: AxiosError) =>
    await Promise.reject(
      error?.message
        ? new Error(error?.message)
        : new Error("Something went wrong")
    )
);

// response interceptors
axios.interceptors.response.use(
  (response: any) => response.data,
  async (error: any) => {
    return await Promise.reject(
      new Error("Something went wrong", { cause: error.response })
    );
  }
);

export default requests;
