import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../../core/locale/strings.json";
import { APP_APPLE_URI, APP_GOOGLE_URL } from "../../../core/constants/url";
import notification from "../../../assets/icons/notifications.svg";
import location from "../../../assets/icons/edit_location.svg";
import myfieldapp from "../../../assets/images/myfieldapp.svg";
import { osfunction } from "../../../core/utils/common-function";
import "./sidebar.less";

interface RouteDTO {
  name: string;
  path: string;
  select: boolean;
}

export const SidebarMenu: React.FC = (props) => {
  const history = useNavigate();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  const { t: translate } = useTranslation();

  const [mainMenu, setMainMenu] = useState<any>({
    dashboard: {
      name: ConstantKey.DASHBOARD,
      path: "/dashboard",
      select: false,
    },
    mapview: {
      name: ConstantKey.MAP_VIEW,
      path: "/mapview",
      select: false,
    },
    sparyclock: {
      name: ConstantKey.SPARY_CLOCK,
      path: "/sparyclock",
      select: false,
    },
    forecase: {
      name: ConstantKey.FORECAST,
      path: "/forecast",
      select: false,
    },
  });
  const [recommendations, setRecommendations] = useState<any>({
    seedrecommendations: {
      name: ConstantKey.SEED_RECOMMENDATIONS,
      path: "/product-recommendations/M",
      select: false,
    },
    summaryinstights: {
      name: ConstantKey.SUMMARY_INSIGHTS,
      path: "/summaryinsights",
      select: false,
    },
  });

  const [offersMenu, setOffersMenu] = useState<any>({
    newoffers: {
      name: ConstantKey.NEW_OFFERS,
      path: "/newoffers",
      select: false,
    },
    committedoffers: {
      name: ConstantKey.COMMITED_OFFERS,
      path: "/committedoffers",
      select: false,
    },
    claimed: {
      name: ConstantKey.CLAIMED,
      path: "/claimed",
      select: false,
    },
  });

  const selectMenuItemOnClick = (
    i: number,
    stateItem: PropertyKey,
    path: string
  ): void => {
    const changeMainMenu: Record<string, RouteDTO> = {};
    Object.keys(mainMenu).forEach((ele, index) => {
      if (i === index && ele === stateItem) {
        changeMainMenu[ele] = {
          ...mainMenu[ele],
          select: !mainMenu[ele].select,
        };
      } else {
        changeMainMenu[ele] = { ...mainMenu[ele], select: false };
      }
    });
    setMainMenu(changeMainMenu);

    const changeRecommendations: Record<string, RouteDTO> = {};
    Object.keys(recommendations).forEach((ele, index) => {
      if (i === index && ele === stateItem) {
        changeRecommendations[ele] = {
          ...recommendations[ele],
          select: !recommendations[ele].select,
        };
      } else {
        changeRecommendations[ele] = { ...recommendations[ele], select: false };
      }
    });
    setRecommendations(changeRecommendations);

    const changeOfferMenu: Record<string, RouteDTO> = {};
    Object.keys(offersMenu).forEach((ele, index) => {
      if (i === index && ele === stateItem) {
        changeOfferMenu[ele] = {
          ...offersMenu[ele],
          select: !offersMenu[ele].select,
        };
      } else {
        changeOfferMenu[ele] = { ...offersMenu[ele], select: false };
      }
    });
    setOffersMenu(changeOfferMenu);
    history(path);
  };
  const displayMenus = (menuItems: Record<any, RouteDTO>): JSX.Element => {
    return (
      <>
        {Object.keys(menuItems).map((ele, index) => {
          return (
            <div className="side-submenu-item" key={menuItems[ele].path}>
              <button
                onClick={() => {
                  selectMenuItemOnClick(index, ele, menuItems[ele].path);
                }}
                className={`mf-btn-img side-menu-text mf-cursor-pointer ${
                  menuItems[ele].select ? "side-menu-item-active" : ""
                }`}
              >
                {translate(menuItems[ele].name)}
              </button>
            </div>
          );
        })}
      </>
    );
  };

  const onClickGetNow = (): void => {
    const os = osfunction();
    if (os === "MacOS") window.location.href = APP_APPLE_URI;
    else window.location.href = APP_GOOGLE_URL;
  };
  return (
    <div className="syt-antd-menu-item svg">
      <div>
        <li className="side-menu-banner-li side-menu-user-name">
          {translate(ConstantKey.HI)},{" "}
          {profileDataSelector.profileData.firstName
            ? profileDataSelector.profileData.firstName
            : "NA"}
        </li>
        {/* <li className="side-menu-banner-li side-menu-points">15,00pts</li> */}
      </div>
      <div className="side-menu-user">
        <span>
          <img src={location} alt="location" />
          {profileDataSelector?.userLocation
            ? profileDataSelector?.userLocation
            : "Location not found!"}
        </span>
        <span>
          <img src={notification} alt="notification" />
          {/* <span className="side-menu-notification-number">2</span> */}
        </span>
      </div>
      <div className="side-menu-items">
        <div className="side-menu-banner">
          <div className="side-menu-banner-text">
            <li className="side-menu-banner-li">
              {translate(ConstantKey.DOWNLOAD)}
            </li>
            <li className="side-menu-banner-li">
              {translate(ConstantKey.MY_FIELD_APP)}{" "}
            </li>
            <Button
              type={ButtonType.outline}
              className="banner-button-white"
              size="small"
              onClick={onClickGetNow}
            >
              {translate(ConstantKey.GET_NOW)}
            </Button>

            <img
              src={myfieldapp}
              className="side-menu-banner-image"
              alt="myfieldapp"
            />
          </div>
        </div>
      </div>
      <div className="side-menu-items">{displayMenus(mainMenu)}</div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          {translate(ConstantKey.MY_OFFERS)}
        </div>
        {displayMenus(offersMenu)}
      </div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          {translate(ConstantKey.RECOMMENDATIONS)}
        </div>
        {displayMenus(recommendations)}
      </div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          {translate(ConstantKey.NEW_PRODUCTS_AND_SERVICES)}
        </div>
      </div>
      <div className="side-menu-items">
        <div className="side-menu-label">
          {translate(ConstantKey.CONTACT_SYNGENTA)}
        </div>
      </div>
    </div>
  );
};
