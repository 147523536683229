import { useEffect } from "react";
import { AuthenticationOptions } from "../../core/types/authentication.dto";

export const LoginComponent = (): JSX.Element => {
  const initOAuth2Flow = (returnStateValue?: string): void => {
    const returnState = returnStateValue
      ? `&state=${btoa(returnStateValue)}`
      : "";
    const oauthURL = process.env.REACT_APP_CROPWISE_BASE_URL;
    if (oauthURL) {
      // replacing the route history `/login - redirect URL` to splash page
      window.location.href = `${oauthURL}/oauth/authorize?response_type=code&client_id=${AuthenticationOptions.clientId}&redirect_uri=${AuthenticationOptions.redirectUri}${returnState}`;
    }
  };

  useEffect(() => {
    initOAuth2Flow();
  }, []);

  return <p>Redirecting to Login page...</p>;
};

export default LoginComponent;
