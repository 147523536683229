import cloud1 from "./cloud-1.svg";
import cloud2 from "./cloud-2.svg";
import cloud3 from "./cloud-3.svg";
import cloud4 from "./cloud-4.svg";
import cloud5 from "./cloud-5.svg";
import cloud6 from "./cloud-6.svg";
import cloud7 from "./cloud-7.svg";
import cloud8 from "./cloud-8.svg";
import cloud9 from "./cloud-9.svg";
import cloud10 from "./cloud-10.svg";
import cloud11 from "./cloud-11.svg";
import cloud12 from "./cloud-12.svg";
import cloud13 from "./cloud-13.svg";
import cloud14 from "./cloud-14.svg";
import cloud15 from "./cloud-15.svg";
import cloud16 from "./cloud-16.svg";
import cloud17 from "./cloud-17.svg";
import cloud18 from "./cloud-18.svg";
import cloud19 from "./cloud-19.svg";

export default {
  cloud1,
  cloud2,
  cloud3,
  cloud4,
  cloud5,
  cloud6,
  cloud7,
  cloud8,
  cloud9,
  cloud10,
  cloud11,
  cloud12,
  cloud13,
  cloud14,
  cloud15,
  cloud16,
  cloud17,
  cloud18,
  cloud19,
};
