import { useEffect, useCallback } from "react";
import { axiosRequest, getAuthorizationHeaders } from "../../core/api/axios";
import type { AxiosResponse } from "axios";
import qs from "qs";
import {
  type IAuthTokenResponseDTO,
  defaultAuthRequestParams,
} from "../../core/types/authentication.dto";
import { useLocation } from "react-router-dom";
import { sendAmplitudeData } from "../../core/utils/analytics";

export const AuthenticationRedirectComponent = (): JSX.Element => {
  const location = useLocation();
  const code = new URLSearchParams(location?.search).get("code");

  const getOAuthToken = useCallback(async () => {
    const config = {
      headers: getAuthorizationHeaders,
    };
    const authTokenRequestDTO = {
      ...defaultAuthRequestParams,
      code,
    };
    const response = await axiosRequest.post<
      any,
      AxiosResponse<IAuthTokenResponseDTO>
    >("/oauth/token", qs.stringify(authTokenRequestDTO as any), config);
    return response.data;
  }, []);

  useEffect(() => {
    getOAuthToken()
      .then((tokens: any) => {
        if (tokens) {
          sendAmplitudeData("LOGIN SUCCESS");
          localStorage.setItem("oauth_api_response", JSON.stringify(tokens));
          localStorage.setItem("access_tokens", tokens.access_token);
          localStorage.setItem("refresh_tokens", tokens.refresh_token);
          window.location.reload();
        }
      })
      .catch(() => {});
  }, []);

  return <div>Redirecting you to the application...</div>;
};

export default AuthenticationRedirectComponent;
