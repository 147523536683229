import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Button,
  ButtonType,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";
import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers/";
import { setBannerDetails } from "../../core/redux/reducers/productTemplateSlice";
import ConstantKey from "../../core/locale/strings.json";
import MoreSyngentaTiles from "../moresyngentatiles";
import Banners from "../../shared/components/Banners";
import { BannerType } from "../../core/types/banners.dto";
import mapView from "../../assets/images/mapview.svg";
import ForeCastHome from "../forecastHome";
import "./dashboard.less";

export const AppDashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const ProductTemplate = useSelector(
    (state: ApplicationState) => state.productTemplateDetails
  );

  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode) {
      const countryCode =
        ProfileDataSelector.defaultLocation?.countryCode?.toUpperCase();
      const newOffers: any = [];
      const alertsRecommendation: any = [];
      const newProduct: any = [];
      const claimedOffers: any = [];
      const committedOffers: any = [];
      ProductTemplateApi.getBannerTemplateApi(countryCode)
        .then((res) => {
          res.content?.forEach((banner: any) => {
            if (
              banner.bannerCategory === "ALERT" ||
              banner.bannerCategory === "RECOMMENDATION" ||
              banner.bannerCategory === "REPORT"
            )
              alertsRecommendation.push(banner);
            else if (banner.bannerCategory === "NEW_OFFER") {
              if (banner.offerStatus === "CLAIMED") claimedOffers.push(banner);
              else if (banner.offerStatus === "COMMITTED")
                committedOffers.push(banner);
              else newOffers.push(banner);
            } else if (
              banner.bannerCategory === "NEW_PRODUCT" ||
              banner.bannerCategory === "SERVICES"
            )
              newProduct.push(banner);
          });
          const banners = {
            newOffers,
            alertsRecommendation,
            newProduct,
            claimedOffers,
            committedOffers,
          };
          dispatch(setBannerDetails(banners));
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.profileData]);

  const onClickButton = (): void => {
    navigate("/mapview");
  };

  return (
    <AntdConfigProvider>
      <div className="mf-grid-story-container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <div className="mapview-container">
              <img src={mapView} alt="weatherMapStatic" className="mapview" />
              <Button
                onClick={onClickButton}
                className="mapview-button"
                type={ButtonType.primary}
              >
                {translate(ConstantKey.Explore_weather_map)}
              </Button>
            </div>
            {ProductTemplate?.bannerDetails?.newOffers?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.NEW_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundColor}
                data={
                  ProductTemplate?.bannerDetails?.newOffers
                    ? ProductTemplate?.bannerDetails?.newOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.committedOffers?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.COMMITED_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.committedOffers
                    ? ProductTemplate?.bannerDetails?.committedOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.claimedOffers?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.CLAIMED_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.claimedOffers
                    ? ProductTemplate?.bannerDetails?.claimedOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.alertsRecommendation?.length !==
              0 && (
              <Banners
                title={translate(
                  ConstantKey.RECOMMENDATIONS_ALERTS_AND_REPORTS
                )}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.alertsRecommendation
                    ? ProductTemplate?.bannerDetails?.alertsRecommendation
                    : []
                }
              />
            )}
            <MoreSyngentaTiles />
            {ProductTemplate?.bannerDetails?.newProduct?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.new_products_and_services)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.newProduct
                    ? ProductTemplate?.bannerDetails?.newProduct
                    : []
                }
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <ForeCastHome />
          </Col>
        </Row>
      </div>
    </AntdConfigProvider>
  );
};

export default AppDashboard;
