import {
  Row,
  Col,
  Button,
  AntdConfigProvider,
  ButtonType,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import {
  type BannerDataDTO,
  type BannersDTO,
} from "../../../core/types/banners.dto";
import ConstantKey from "../../../core/locale/strings.json";
import "./banners.less";

const Banners = (props: BannersDTO): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <AntdConfigProvider>
      <div>
        <div className="banner-title mf-mt-15 mf-pb-10">{props.title}</div>
        <Row align="top" justify="start">
          {props.data?.map((ele: BannerDataDTO, i: number) => {
            return (
              <Col
                lg={12}
                md={12}
                sm={24}
                key={ele.bannerRefId}
                className={`banner-column ${
                  props.data.length - 1 !== i ? "mf-pr-10" : ""
                }`}
              >
                <div
                  className={"card-box"}
                  style={{
                    backgroundImage: `url(${ele.imageRefId})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundPositionY: "100%",
                  }}
                ></div>
                <div className="banner-btn-title-align ">
                  {ele?.name && <p className="banner-desc">{ele.name}</p>}
                  {ele?.redirectUrl && (
                    <Button
                      onClick={() => {
                        ele?.redirectUrl && window.open(ele.redirectUrl);
                      }}
                      className={"banner-button"}
                      type={ButtonType.primary}
                    >
                      {translate(ConstantKey.VIEW_MORE)}
                    </Button>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default Banners;
