import { useTranslation } from "react-i18next";
import ConstantKey from "../../../core/locale/strings.json";
import "./globalfooter.less";
const GlobalFooter = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="footerblock">
      <div>{translate(ConstantKey.FOOTER_MSG_TEXT)} </div>{" "}
      <div>&#169;{translate(ConstantKey.SYNGENTA_COPYRIGHT)}</div>
    </div>
  );
};

export default GlobalFooter;
