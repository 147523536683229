import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  AntdConfigProvider,
  Button,
  ButtonType,
} from "syngenta-digital-cropwise-react-ui-kit";
import { unionWith, isEqual } from "lodash";
import type { ApplicationState } from "../../core/redux/reducers";
import ConstantKey from "../../core/locale/strings.json";
import ForeCastApi from "../../core/api/forecastApi";
import {
  setForcastWeeklyData,
  setForecastHourlyTodayData,
} from "../../core/redux/reducers/forecastSlice";
import { weatherCEHumUrl } from "../../core/constants/url";
import weatherImages from "../../assets/weatherImages";
import wind from "../../assets/icons/wind.svg";
import rain from "../../assets/icons/rain.svg";
import humidity from "../../assets/icons/humidity.svg";
import soilmoisture from "../../assets/icons/soilmoisture.svg";
import winddirectionIcons from "../../assets/winddirectionIcons";
import "./forecasthome.less";

interface DailyData {
  DATE_TIMESTAMP: Date;
  Date: string;
  Day: string;
  Month: string;
  PictoCode_Daily: string;
  TempSurface_DailyAvg: string;
  TempSurface_DailyMax: string;
  TempSurface_DailyMin: string;
}

export const getDirectionIcons = (windIocn: string): any => {
  let direction: null | string = null;
  if (windIocn === "E") direction = "east";
  else if (windIocn === "SE") direction = "southeast";
  else if (windIocn === "NE") direction = "northeast";
  else if (windIocn === "N") direction = "north";
  else if (windIocn === "NW") direction = "northwest";
  else if (windIocn === "W") direction = "west";
  else if (windIocn === "S") direction = "south";
  else if (windIocn === "SW") direction = "southwest";
  return winddirectionIcons[direction as keyof typeof winddirectionIcons];
};

export const getIconSVGFile = (pictoCode: string): string => {
  const iconName = `cloud${pictoCode}`;
  const icon = weatherImages[iconName as keyof typeof weatherImages];

  if (icon === undefined) {
    throw new Error("Invalid icon: " + pictoCode);
  }

  return icon;
};

const ForeCastHome = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const Forecastdata = useSelector((state: ApplicationState) => state.forecast);
  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  const [forecastWeekly, setForecastWeekly] = useState([]);

  useEffect(() => {
    if (
      profileDataSelector?.profileData?.countryCode &&
      profileDataSelector?.defaultPropertyCoordinates?.lat &&
      profileDataSelector?.defaultPropertyCoordinates?.lng
    ) {
      ForeCastApi.getForecastWeeklyData(
        profileDataSelector?.defaultPropertyCoordinates?.lat,
        profileDataSelector?.defaultPropertyCoordinates?.lng,
        profileDataSelector.profileData?.countryCode
      )
        .then((response) => {
          dispatch(setForcastWeeklyData(response));
        })
        .catch(() => {});
      ForeCastApi.getForecastHourlyTodayData(
        profileDataSelector?.defaultPropertyCoordinates?.lat,
        profileDataSelector?.defaultPropertyCoordinates?.lng,
        profileDataSelector.profileData?.countryCode
      )
        .then((response) => {
          dispatch(setForecastHourlyTodayData(response));
        })
        .catch(() => {});
    }
  }, [
    profileDataSelector?.profileData,
    profileDataSelector?.defaultPropertyCoordinates,
  ]);

  useEffect(() => {
    if (
      Forecastdata?.forecastWeeklyData &&
      Forecastdata?.forecastHourlyTodayData
    ) {
      const forecastWeekly = { ...Forecastdata?.forecastWeeklyData };
      const forecastToday = { ...Forecastdata?.forecastHourlyTodayData };
      Object.keys(forecastWeekly).forEach((day, i) => {
        if (day === Object.keys(forecastToday)[0]) {
          forecastWeekly[day as keyof typeof forecastWeekly] = unionWith(
            [
              ...forecastWeekly[day as keyof typeof forecastWeekly],
              ...forecastToday[day as keyof typeof forecastToday],
            ],
            isEqual
          );
        }
      });
      const weeklyInfo = convertData(forecastWeekly);

      setForecastWeekly(weeklyInfo[0]);
    }
  }, [Forecastdata]);

  const convertData = (data: any): any[] => {
    let modifiedData: any = [];
    Object.keys(data).forEach((ele) => {
      let modifiedObj: any = {};
      data[ele].forEach((obj: any) => {
        const name = obj.name.split(" ");
        modifiedObj = { ...modifiedObj, [name[0]]: obj.value };
      });
      modifiedData.push(modifiedObj);
    });
    modifiedData = modifiedData.sort(
      (a: DailyData, b: DailyData) =>
        new Date(a.DATE_TIMESTAMP).valueOf() -
        new Date(b.DATE_TIMESTAMP).valueOf()
    );

    modifiedData = modifiedData.map((ele: DailyData) => {
      if (new Date(ele.DATE_TIMESTAMP).getDate() === new Date().getDate())
        ele.Day = ConstantKey.TODAY;

      if (new Date(ele.DATE_TIMESTAMP).getDate() === new Date().getDate() - 1)
        ele.Day = ConstantKey.YESTERDAY;
      return ele;
    });
    return [modifiedData];
  };
  const getTime = (date: string): string => {
    const time = date.split(" ")[1].slice(0, 5);
    return time;
  };
  const displayDialyReport = (data: any): JSX.Element => {
    if (data.Day !== ConstantKey.TODAY)
      return (
        <AntdConfigProvider>
          <div className="weatherReport">
            <Row align="top" justify="start" className="forecastrow">
              <Col flex="1" offset="0" order="0" pull="0" push="0">
                <div>
                  <div className="forecastdate">
                    {`${data.Date},${data.Month}`}
                  </div>
                  <div className="forecastweeks">{translate(data.Day)}</div>
                </div>
              </Col>
              <Col flex="1" offset="0" order="1" pull="0" push="0">
                <div>
                  <img
                    alt="weatehr icon"
                    src={getIconSVGFile(data.PictoCode_Daily)}
                    className="weathericon"
                  />
                  <span className="forecasttemp">
                    <span className="weathertemphigh">
                      {Math.round(data.TempAir_DailyMax)}°
                    </span>
                    <span className="weathertemplow">
                      / {Math.round(data.TempAir_DailyMin)}&deg;C
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </AntdConfigProvider>
      );
    else
      return (
        <AntdConfigProvider>
          <div className="weatherreportselected">
            <Row align="top" justify="start" className="forecastrow">
              <Col flex="1" offset="0" order="0" pull="0" push="0">
                <div>
                  <div className="forecastdate">
                    {`${data.Date} ${data.Month}, ${getTime(
                      data.DATE_TIMESTAMP
                    )}`}
                  </div>
                  <div className="forecastweeks">{translate(data.Day)}</div>
                  <div className="forecasttodytemp">
                    <img
                      src={getIconSVGFile(data.PictoCode_Daily)}
                      className="weathericon"
                      alt="weather icon"
                    />
                    <span className="">
                      <span className="weathertemphigh m-r-18">
                        {Math.round(data.TempAir_Hourly)}
                        <span className="degressstyle"> &#8451;</span>
                      </span>
                      <span className="weathertemplow weathertemplowarrows">
                        &uarr;
                      </span>
                      <span className="weathertemplow m-r-18">
                        {Math.round(data.TempAir_DailyMax)}&deg;
                      </span>
                      <span className="weathertemplow weathertemplowarrows">
                        &darr;
                      </span>
                      <span className="weathertemplow">
                        {Math.round(data.TempAir_DailyMin)}&deg;
                      </span>
                    </span>
                  </div>
                  <hr></hr>
                  <AntdConfigProvider>
                    <Row align="top" justify="start" className="forecastrow">
                      <Col
                        flex="1"
                        offset="0"
                        order="0"
                        pull="0"
                        push="0"
                        className="forecastdailycol"
                      >
                        <div className="forecastdailycol">
                          <img
                            src={rain}
                            className="forecastdailyicon"
                            alt="rain"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.RAIN)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.Precip_HourlySum} mm
                            </span>
                          </span>
                        </div>
                        <div className="forecastdailycol m-l-28">
                          <img
                            src={wind}
                            className="forecastdailyicon"
                            alt="wind"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.WIND)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.WindSpeed_Hourly} Km/h&#45;{" "}
                              <img
                                className="arrowimage"
                                alt="arrow icon"
                                src={getDirectionIcons(
                                  data.WindDirection_Hourly
                                )}
                              />
                              {data.WindDirection_Hourly}{" "}
                            </span>
                          </span>
                        </div>
                      </Col>

                      <Col
                        flex="1"
                        offset="0"
                        order="1"
                        pull="0"
                        push="0"
                        className="forecastdailycol"
                      >
                        <div className="forecastdailycol">
                          <img
                            src={humidity}
                            className="forecastdailyicon"
                            alt="forecast daily icon"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.HUMIDITY)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.HumidityRel_Hourly} %
                            </span>
                          </span>
                        </div>
                        <div className="forecastdailycol">
                          <img
                            alt="forecast daily icon"
                            src={soilmoisture}
                            className="forecastdailyicon"
                          />
                          <span className="spancebetween">
                            <span className="icontext">
                              {translate(ConstantKey.SOILMOISTURE)}
                            </span>
                            <br />
                            <span className="icontemp">
                              {data.Soilmoisture_0to10cm_Hourly} Vol.%
                            </span>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </AntdConfigProvider>
                </div>
              </Col>
            </Row>
          </div>
        </AntdConfigProvider>
      );
  };
  const onClickViewMore = (): void => {
    const countryCode =
      profileDataSelector?.profileData?.countryCode?.toUpperCase();
    const languageCode = profileDataSelector?.profileData?.locale;
    let lat = profileDataSelector?.defaultPropertyCoordinates?.lat;
    let lng = profileDataSelector?.defaultPropertyCoordinates?.lng;
    lat = lat && parseFloat(lat?.toFixed(5));
    lng = lng && parseFloat(lng?.toFixed(5));

    const url = weatherCEHumUrl(countryCode, languageCode, lat, lng);
    window.open(url, "_blank");
  };
  return (
    <div className="forecastalinment">
      <AntdConfigProvider>
        <div className="forecastviewmore">
          <Row align="top" justify="start" className="forecastrow">
            <Col flex="1" offset="0" order="0" pull="0" push="0">
              <div>{translate(ConstantKey.ADVANCED_FORECAST_DATA)}</div>
              <div className="forecastviewmoreinfobelow">
                {translate(ConstantKey.EVAPORTATION_HUMIDITY_AND_MORE)}
              </div>
            </Col>
            <Col
              flex="1"
              offset="0"
              order="1"
              pull="0"
              push="0"
              className="forecastviewmorebutton"
            >
              <Button type={ButtonType.primary} onClick={onClickViewMore}>
                {translate(ConstantKey.VIEW_MORE)}
              </Button>
            </Col>
          </Row>
        </div>
      </AntdConfigProvider>

      {forecastWeekly?.map((week) => displayDialyReport(week))}
    </div>
  );
};
export default ForeCastHome;
