import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import type {
  LoaderDTO,
  InlineErrorDTO,
  InlineLoaderDTO,
} from "./../../core/types/loader.dto";

export const Loader = ({ message, mask }: LoaderDTO): ReactElement => {
  const { t: translate } = useTranslation();
  return (
    <div>
      {mask && <div className="mask" />}
      <div className="loader-wrapper">
        <div className="loader-container">
          <div className="loader" />
          {Boolean(message) && (
            <div className="loader-text">
              {translate(message ?? "Loading ...")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const InlineErrorMessage: FC<InlineErrorDTO> = ({ text }) => {
  const { t: translate } = useTranslation();
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: "150px",
        fontStyle: "oblique",
        fontSize: "16px",
        color: "#acb4b7",
        fontFamily: "cursive",
      }}
    >
      <p>
        <InfoCircleOutlined style={{ fontSize: 34, color: "#acb4b7" }} />
      </p>
      {translate(
        text ?? "Error occured while processing your request. Please retry."
      )}
    </div>
  );
};

export const InlineLoader = ({
  padding,
  text,
}: InlineLoaderDTO): JSX.Element => {
  const { t: translate } = useTranslation();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#a3dfa5" }} spin />
  );
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: !(padding ?? false) ? "" : "150px",
        fontStyle: "oblique",
        fontSize: "16px",
        color: "#747778",
        fontFamily: "cursive",
      }}
    >
      <p>
        <Spin indicator={antIcon} />
      </p>
      {translate(text ?? "Loading data...")}
    </div>
  );
};
