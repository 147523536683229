import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AntdConfigProvider,
  Layout,
  Sidebar,
} from "syngenta-digital-cropwise-react-ui-kit";
import { type RoutesDto } from "../../../core/types/routes.dto";

import { GlobalHeader } from "../header";
import { SidebarMenu } from "../sidebar";
import GlobalFooter from "../footer";

interface LayoutWithMenuProps {
  children: ReactNode;
  routesWithoutMenu: RoutesDto[];
}

export const LayoutWithMenu = ({
  children,
  routesWithoutMenu,
}: LayoutWithMenuProps): JSX.Element => {
  const location = useLocation();
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<string>("");

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  const isLayoutWithoutMenu = useCallback(
    (currentLocation: string) => {
      const hideMenu: RoutesDto | undefined = routesWithoutMenu.find(
        (route) => route.path === currentLocation
      );
      if (hideMenu) {
        return {
          hideTopbar: hideMenu.layoutWithoutHeader,
          hideSidebar: hideMenu.layoutWithoutSideMenu,
        };
      } else {
        return {
          hideTopbar: false,
          hideSidebar: false,
        };
      }
    },
    [routesWithoutMenu]
  );

  return (
    <AntdConfigProvider>
      <Layout>
        {!isLayoutWithoutMenu(currentLocation).hideTopbar && (
          <GlobalHeader
            collapsedMenu={collapsedMenu}
            setCollapsedMenu={setCollapsedMenu}
            hideSideBarBtn={isLayoutWithoutMenu(currentLocation).hideSidebar}
          />
        )}
        <Layout>
          {!isLayoutWithoutMenu(currentLocation).hideSidebar && (
            <Sidebar
              expandedIcon={<SidebarMenu />}
              collapsedMenu={collapsedMenu}
              theme="light"
              className="mf-app-sidebar"
            />
          )}
          <Layout className="stopscrolling">{children}</Layout>
        </Layout>
        <GlobalFooter />
      </Layout>
    </AntdConfigProvider>
  );
};

export default LayoutWithMenu;
