/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { type ErrorInfo } from "react";
import { withTranslation } from "react-i18next";
import ConstantKey from "../../core/locale/strings.json";
import "./style.less";

class ErrorBoundary extends React.Component<
  { children: any; t: any },
  { hasError: boolean; error: any; info: any }
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error, info });
  }

  public render() {
    const { t: translate } = this.props;

    if (this.state.hasError) {
      return (
        <div>
          <h1>{translate(ConstantKey.SOMETHING_WENT_WRONG)}</h1>
          <p style={{ marginTop: 30 }}>
            {translate(ConstantKey.PLEASE_TRY_AFTER_SOME_TIME)}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
