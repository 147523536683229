import React, { useId, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  AntdConfigProvider,
} from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../core/redux/reducers";
import { setSyngentaProductsDetails } from "../../core/redux/reducers/homePageSlice";
import ConstantKey from "../../core/locale/strings.json";
import cropwiseproduct from "../../assets/icons/cropwiseproduct.svg";
import plantPresription from "../../assets/icons/plantPrescription.svg";
import summary from "../../assets/icons/summary.svg";
import report from "../../assets/icons/report.svg";
import myfieldlogo from "../../assets/icons/myfield-icon.svg";
import cropwiselogo from "../../assets/icons/CropwiseLogo.svg";
import "./moresyngentatiles.less";

const MoreSyngentaTiles = (): JSX.Element => {
  const uid = useId();
  const history = useNavigate();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const SyngentaProducts = useSelector(
    (state: ApplicationState) => state.homepage
  );
  useEffect(() => {
    // mock data for cropwise products
    const syngentatitlemockdata = [
      {
        titleIcon: "corpwiselogo",
        productIcon: "cropwiseproduct",
        description: translate(ConstantKey.SEED_VARIETY_RECOMMENDATIONS),
        url: "/product-recommendations/M",
      },
      {
        titleIcon: "corpwiselogo",
        productIcon: "plantPresription",
        description: translate(ConstantKey.VARIABLE_RATEPLANT_PRESCRIPTION),
        url: "/variablerateplant",
      },
      {
        titleIcon: "myfield",
        productIcon: "summary",
        description: translate(ConstantKey.MYFIELD_SUMMARY_INSIGHTS_REPORT),
        url: "/summaryinsights",
      },
      {
        titleIcon: "myfield",
        productIcon: "report",
        description: translate(ConstantKey.FIELD_MONITORING_REPORTS),
        url: "/product-recommendations/L",
      },
    ];
    dispatch(setSyngentaProductsDetails(syngentatitlemockdata));
  }, []);
  const getProductLogo = (log: string): any => {
    if (log === "myfield") return myfieldlogo;
    if (log === "corpwiselogo") return cropwiselogo;
  };
  const fetchSvgIcons = (iconName: string): any => {
    if (iconName === "report") return report;
    else if (iconName === "summary") return summary;
    else if (iconName === "plantPresription") return plantPresription;
    else if (iconName === "cropwiseproduct") return cropwiseproduct;
  };
  const onClickTiles = (url: string): void => {
    history(url);
  };
  return (
    <AntdConfigProvider>
      <div className="grid-story-container">
        <div className="banner-title">
          {translate(ConstantKey.MORE_FROM_SYNGENTA)}
        </div>
        <Row align="top" justify="start">
          {SyngentaProducts?.syngentaProductsDetails?.map(
            (ele: any, i: number) => {
              return (
                <Col
                  flex={1}
                  offset={"0"}
                  order={i}
                  pull="0"
                  push="0"
                  key={uid}
                  onClick={() => {
                    onClickTiles(ele?.url);
                  }}
                >
                  <div className="moresyngentalayout">
                    <div>
                      <span className="moreproduct_titletext">
                        {translate(ConstantKey.POWERED_BY)}
                      </span>
                      <span>
                        <img
                          src={getProductLogo(ele.titleIcon)}
                          className="moreproduct_titleicon"
                          alt="title icons"
                        />
                      </span>
                    </div>
                    <br />
                    <span>
                      <img
                        src={fetchSvgIcons(ele.productIcon)}
                        alt="cropwise product"
                      />
                    </span>
                    <br />

                    <span className="moreproductdescription">
                      {ele.description}
                    </span>
                  </div>
                </Col>
              );
            }
          )}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default MoreSyngentaTiles;
